
.TableGridTree_GraySeparators {
  --gray-border: 1px solid #A5A5A5;
}

.TableGridTree_GraySeparators .MuiTableRow-head:first-child .MuiTableCell-root:nth-child(3) {
  border-right: var(--gray-border) !important;
  border-left: var(--gray-border) !important;
}

.TableGridTree_GraySeparators
  .MuiTableRow-head:nth-child(2)
  .MuiTableCell-root:nth-child(5) {
  border-left: var(--gray-border) !important;
}

.TableGridTree_GraySeparators
  .MuiTableRow-head:nth-child(2)
  .MuiTableCell-root:nth-child(7) {
  border-right: var(--gray-border) !important;
}

.TableGridTree_GraySeparators .MuiTableBody-root .MuiTableCell-root:nth-child(5) {
  border-left: var(--gray-border) !important;
}

.TableGridTree_GraySeparators .MuiTableBody-root .MuiTableCell-root:nth-child(7) {
  border-right: var(--gray-border) !important;
}
