@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

.e-ddl.e-control-wrapper .e-ddl-icon::before {
  transform: rotate(0deg);
  transition: transform 300ms ease;
}

.e-ddl.e-control-wrapper.e-icon-anim .e-ddl-icon::before {
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.e-dropdownbase .e-list-item.e-active.e-hover {
  color: #e3165b;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:active,
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:hover,
.e-input-group:not(.e-disabled) .e-back-icon:active,
.e-input-group:not(.e-disabled) .e-back-icon:hover,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:active,
.e-popup.e-ddl .e-input-group:not(.e-disabled) .e-clear-icon:hover {
  background: transparent;
}

.e-input-group .e-ddl-icon:not(:active)::after {
  animation: none;
}

.e-ddl.e-popup {
  border: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin-top: 2px;
}

.e-popup.e-ddl .e-dropdownbase {
  min-height: 26px;
}

.e-bigger .e-popup.e-ddl-device-filter .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  border-color: #e0e0e0;
}

.e-bigger .e-popup.e-ddl-device-filter {
  margin-top: 0;
}

.e-bigger .e-ddl-device .e-input-group,
.e-bigger .e-ddl-device .e-input-group.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) {
  background: #f5f5f5;
  border-width: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.e-bigger .e-ddl-device .e-input-group .e-back-icon,
.e-bigger .e-ddl-device .e-input-group input.e-input,
.e-bigger .e-ddl-device .e-input-group .e-clear-icon {
  background-color: #f5f5f5;
}

.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
  margin: 6px 6px 5px;
  min-height: 12px;
  min-width: 12px;
  padding: 6px;
}

.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon {
  min-height: 16px;
  min-width: 16px;
}

.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-filter {
  padding: 8px 16px 8px 0;
}

.e-input-group.e-ddl,
.e-input-group.e-ddl .e-input,
.e-input-group.e-ddl .e-ddl-icon {
  background: transparent;
}

.e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group:hover:not(.e-disabled):not(.e-float-icon-left),
.e-ddl.e-ddl-device.e-ddl-device-filter .e-input-group.e-control-wrapper:hover:not(.e-disabled):not(.e-float-icon-left) {
  border-bottom-width: 0;
}

.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus.e-small .e-clear-icon,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus .e-clear-icon,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-input-focus .e-clear-icon {
  margin: 0;
}

.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-input-focus .e-input-filter,
.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small.e-input-focus .e-input-filter {
  padding: 5px 5px 5px 12px;
}

.e-bigger.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-bigger.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group .e-clear-icon,
.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-small .e-clear-icon,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger .e-clear-icon,
.e-popup.e-ddl:not(.e-ddl-device) .e-input-group.e-bigger.e-small .e-clear-icon {
  min-height: 18px;
  min-width: 18px;
}

.e-bigger.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-bigger.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group .e-input-filter,
.e-bigger .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
.e-bigger.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-small .e-input-filter,
.e-small .e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
.e-small.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger .e-input-filter,
.e-popup.e-ddl:not(.e-ddl-device) .e-filter-parent .e-input-group.e-bigger.e-small .e-input-filter {
  padding: 8px 16px;
}

.e-ddl.e-popup.e-outline .e-filter-parent {
  padding: 4px 8px;
}

@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*! component's theme wise override definitions and variables */
@keyframes tbar-popup-shadow {
  0% {
    border-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  100% {
    box-shadow: 0 0 0 200px rgba(255, 255, 255, 0.12);
  }
}

.e-treegrid .e-treegridexpand::before,
.e-treegrid .e-treegridcollapse::before {
  content: "";
}

.e-treegrid .e-toolbar-item .e-expand::before {
  content: "";
}

.e-treegrid .e-toolbar-item .e-collapse::before {
  content: "";
}

.e-treegrid .e-toolbar-item .e-indent::before {
  content: "";
}

.e-treegrid .e-toolbar-item .e-outdent::before {
  content: "";
}

.e-bigger .e-treegrid .e-treegridexpand,
.e-bigger .e-treegrid .e-treegridcollapse {
  height: 18px;
  width: 18px;
}

.e-bigger .e-treegrid .e-rowcell.e-treerowcell {
  padding-left: 25px;
}

.e-bigger .e-treegrid .e-hierarchycheckbox {
  padding-left: 2px;
}

.e-bigger .e-treegrid .e-treecell {
  line-height: 18px;
}

.e-treegrid .e-treegridexpand::before,
.e-treegrid .e-treegridcollapse::before {
  vertical-align: middle;
}

.e-treegrid .e-rowcell:not(.e-gridclip) .e-treecolumn-container {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-treegrid .e-rowcell:not(.e-gridclip) .e-treecolumn-container span.e-treecell {
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-treegrid .e-treegridexpand:hover::before,
.e-treegrid .e-treegridcollapse:hover::before {
  color: #000;
}

.e-treegrid .e-rowcell.e-treerowcell {
  padding-left: 18px;
}

.e-treegrid .e-rtl .e-treegridcollapse {
  transform: scale(-1);
}

.e-treegrid .e-treegridexpand {
  transform: rotate(90deg);
}

.e-treegrid .e-treerowexpanded:not(.e-hiddenrow) {
  display: table-row;
}

.e-treegrid .e-treerowcollapsed {
  display: none;
}

.e-treegrid .e-treecheckbox-container {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-treegrid .e-treecheckbox-container :not(.e-treecheckbox) {
  white-space: nowrap;
}

.e-treegrid .e-treecheckbox {
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.e-treegrid .e-treecolumn-container span {
  display: inline-block;
  vertical-align: middle;
}

.e-treegrid .e-treecolumn-container .e-hierarchycheckbox .e-frame {
  height: 18px;
}

.e-treegrid .e-checkselection .e-frozenheader .e-rowcell,
.e-treegrid .e-checkselection .e-movableheader .e-rowcell,
.e-treegrid .e-checkselection .e-frozencontent .e-rowcell,
.e-treegrid .e-checkselection .e-movablecontent .e-rowcell {
  line-height: 21px;
}

.e-treegrid .e-treegridexpand,
.e-treegrid .e-treegridcollapse {
  color: #000;
  cursor: pointer;
  font-size: 10px;
  height: 16px;
  text-align: center;
  vertical-align: bottom;
  width: 16px;
}

.e-treegrid .e-treecell {
  display: table-cell;
  line-height: 16px;
}

.e-treegrid .e-grid.e-wrap .e-rowcell .e-treecolumn-container {
  white-space: nowrap;
}

.e-treegrid .e-grid.e-wrap .e-rowcell .e-treecolumn-container .e-treecell {
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}

.e-treegrid .e-grid.e-wrap .e-rowcell .e-treecheckbox-container {
  white-space: nowrap;
}

.e-treegrid .e-grid.e-wrap .e-rowcell .e-treecheckbox-container .e-treecheckbox {
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}

.e-treegrid.e-grid.e-print-grid-layout .e-icons {
  display: inline-block;
}

.e-treegrid .e-treecheckselect,
.e-treegrid .e-treeselectall {
  margin: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.e-treegrid .e-detailheadercell,
.e-treegrid .e-headercontent .e-table .e-detail-intent,
.e-treegrid .e-gridcontent .e-table .e-detail-intent,
.e-treegrid .e-table .e-detailrow .e-detailindentcell,
.e-treegrid .e-table .e-detailrowexpand,
.e-treegrid .e-table .e-detailrowcollapse,
.e-treegrid .e-filterbarcell.e-mastercell {
  display: none;
}

.e-treegrid .e-icons.e-errorelem {
  display: inline-block;
  padding-left: 10px;
  vertical-align: middle;
}

.e-treegrid .e-errorelem::before {
  color: #e3165b;
  content: "";
  transform: rotate(180deg);
}

.e-treegrid .e-gridcontent td.e-childborder {
  border-color: #e3165b;
  border-width: 1px 0 0;
  box-shadow: 0 1px 0 0 #e3165b;
  position: relative;
  z-index: 1;
}

.e-treegrid .e-gridcontent td.e-rowcell.e-childborder.e-dragborder,
.e-treegrid .e-gridcontent td.e-rowcell.e-dropbottom.e-dragborder td.e-rowdragdrop.e-dropbottom.e-dragborder {
  box-shadow: 0 1px 0 0 #e3165b;
}

.e-treegrid .e-gridcontent td.e-rowcell.e-dragborder,
.e-treegrid .e-gridcontent tr.e-row:first-child .e-rowcell.e-dragborder,
.e-treegrid .e-gridcontent .e-rowdragdrop.e-dragborder {
  box-shadow: 0 0 0 0;
}

.e-treegrid .e-gridcontent td.e-childborder:first-child {
  border-left: 1px solid #e3165b;
  box-shadow: 0 1px 0 0 #e3165b;
  position: relative;
  z-index: 1;
}

.e-treegrid .e-gridcontent td.e-rowcell.e-childborder:last-child {
  border-right: 1px solid #e3165b;
}

.e-treegrid .e-gridcontent td.e-lastrowcell.e-childborder {
  border-width: 1px 0;
}

.e-treegrid .e-gridcontent td.e-lastrowcell.e-childborder:last-child {
  border-right: 1px solid #e3165b;
}

.e-treegrid .e-gridcontent td.e-lastrowcell.e-childborder:first-child {
  border-left: 1px solid #e3165b;
}

.e-treegrid .e-gridcontent td.e-dropbottom:first-child,
.e-treegrid .e-gridcontent td.e-rowcell.e-dropbottom,
.e-treegrid .e-gridcontent td.e-dropbottom {
  box-shadow: 0 1px 0 0 #e3165b;
  position: relative;
  z-index: 1;
}

.e-treegrid .e-gridcontent td.e-droptop:first-child,
.e-treegrid .e-gridcontent td.e-rowcell.e-droptop {
  border-top-color: #e3165b;
}

.e-treegrid .e-gridcontent td.e-lastrowcell.e-dropbottom {
  border-bottom-color: #e3165b;
}

.e-treegrid .e-gridcontent td.e-rowdragdrop.e-droptop.e-dragborder {
  box-shadow: 0 0 0 0;
}

.e-treegrid .e-gridcontent .e-gridheader .e-firstrow-dragborder {
  height: 1px;
}

.e-treegrid .e-gridcontent .e-lastrow-dragborder {
  height: 0;
}

.e-treegrid .e-gridheader .e-firstrow-dragborder {
  height: 0;
}

.e-treegrid .e-droptop .e-lastrow-dragborder {
  height: 0;
}

.e-treegrid .e-treegrid-relative {
  position: relative;
}

.e-treegrid .e-gridheader .e-firstrow-dragborder tr:first-child.e-dropbottom {
  height: 0;
}

.e-treegrid .e-gridheader .e-firstrow-border,
.e-treegrid .e-gridcontent .e-lastrow-border {
  background-color: #e3165b;
  bottom: 0;
  display: none;
  height: 1px;
  position: absolute;
  z-index: 1;
}

.e-treegrid .e-grid .e-gridcontent tr.e-row:first-child td.e-rowcell.e-childborder,
.e-treegrid .e-grid .e-gridcontent tr.e-row:first-child td.e-rowdragdrop.e-childborder {
  border-bottom: 1px solid #e3165b;
  border-top: 1px solid #e3165b;
  box-shadow: 0 0 0 0 #e3165b;
}

.e-treegrid .e-grid .e-gridcontent tr.e-row:first-child td.e-rowcell.e-droptop,
.e-treegrid .e-grid .e-gridcontent tr.e-row:first-child td.e-rowdragdrop.e-droptop {
  border-top: 1px solid #e3165b;
}

.e-treegrid .e-grid .e-gridcontent td.e-rowcell.e-childborder, .e-treegrid .e-grid .e-gridcontent td.e-rowcell.e-dropbottom {
  border-bottom: 1px solid #e3165b;
  box-shadow: 0 0 0 0 #e3165b;
}

.e-treegrid .e-grid .e-gridcontent td.e-rowdragdrop.e-childborder, .e-treegrid .e-grid .e-gridcontent td.e-rowdragdrop.e-dropbottom {
  border-bottom: 1px solid #e3165b;
  box-shadow: 0 0 0 0 #e3165b;
}
