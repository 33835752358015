.tree-picker-menu-dark-theme-bordered {
    background-color: #21232D !important;
    border-left: 1px solid #838388;
    border-right: 1px solid #838388;
    border-bottom: 1px solid #838388;
    color: #fff;
}

.tree-picker-menu-dark-theme-bordered .rs-tree {
    background-color: #21232D !important;
    color: #fff;
}

.tree-picker-menu-dark-theme-bordered .rs-tree-node-label-content:active {
    background-color: #838388 !important;
}

.tree-picker-menu-dark-theme-bordered .rs-tree-node > .rs-tree-node-label > .rs-tree-node-label-content:hover {
    background-color: #838388;
}

.tree-picker-menu-dark-theme-bordered input {
    background-color: #21232D !important;
    border: 1px solid #838388 !important;
}

.tree-picker-dark-theme-bordered a[role = "combobox"] {
    background: transparent !important;
    border: 1px solid #838388 !important;
}

.tree-picker-menu-dark-theme-bordered .rs-tree-node-active > .rs-tree-node-label > .rs-tree-node-label-content {
    background-color: #838388 !important;
    color: #fff;
}

.tree-picker-menu-dark-theme-bordered .rs-tree-node.text-muted.rs-tree-node-disabled span {
    color:  #FFFFFFB2 !important;
}

.rs-picker-value-list {
    color: black !important;
}