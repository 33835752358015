.pagination {
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  list-style: none;
  outline: none;
  padding: 0;
}
.pagination > .active > a{
  background-color: orange ;
  border-color: orange ;
}
.pagination > li > a{
  border: 1px solid orange ;
  color: black !important;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: orange ;
  border-color: orange;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: orange
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}